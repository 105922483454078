import { useState, createContext, useMemo} from 'react';

export const AppContext = createContext();

export const AppProvider = ({ children, subMenu, categoryPage, footer, endpoint, colorPalette }) => {


  
  const [categoryPageData, setCategoryPageData] = useState(categoryPage);
  const [subMenuData, setSubMenuData] = useState(subMenu);
  const [footerData, setfooterData] = useState(footer);
  const [endpointData, setEndpoint] = useState(endpoint);
  const [colorPaletteData, setColorPalette] = useState(colorPalette);
 

  const value = useMemo(() => 
  ({ 
    subMenuData, setSubMenuData, 
    categoryPageData, setCategoryPageData, 
    footerData, setfooterData,
    endpointData, setEndpoint,
    colorPaletteData,setColorPalette,
  }), 
  [ subMenuData, categoryPageData, footerData, endpointData, colorPaletteData ]);
 
  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

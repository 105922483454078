import { request as _request, } from 'graphql-request';
import { gql } from '@apollo/client';
import { slugify } from './url-helpers/urlHelpers'
import * as Sentry from "@sentry/nextjs";
import {
  PRODUCT_REVIEW_FIELDS,
  ASSET_FIELDS,
  BRAND_FIELDS,
  PROMOTION_FIELDS,
  DEEPLINK_FIELDS,
  METADATA_FIELDS,
  AUTHOR_FIELDS,
  POST_FIELDS,
  POST_WITH_RTF_FIELDS,
  PAGE_FIELDS,
  NAVIGATION_MENU_FIELDS,
  SITE_CONFIG_FIELDS
 } from './fragments';
const graphqlAPI = process.env["NEXT_PUBLIC_GRAPHQL_API"]

const headers = {}

if (process.env.GRAPHCMS_CONTENT_API_TOKEN) {
  headers['Authorization'] = 'Bearer ' + process.env.GRAPHCMS_CONTENT_API_TOKEN
}
export const request = async (query, variables) => {
  if (!graphqlAPI || graphqlAPI.length < 10) {
    throw new Error('Please provide a valid GraphQL API endpoint');
  }

  const queryWithFragments = gql `
    ${NAVIGATION_MENU_FIELDS}
    ${ASSET_FIELDS}
    ${PROMOTION_FIELDS}
    ${BRAND_FIELDS}
    ${DEEPLINK_FIELDS}
    ${METADATA_FIELDS}
    ${AUTHOR_FIELDS}
    ${POST_FIELDS}
    ${POST_WITH_RTF_FIELDS}
    ${PAGE_FIELDS}
    ${PRODUCT_REVIEW_FIELDS}
    ${SITE_CONFIG_FIELDS}
    ${query}
  `
try{
    return await _request(graphqlAPI, queryWithFragments, variables, headers)
  }
  catch (e) {
    console.log(e)
    if (e.response) {
      if (e.response.status) console.error('HTTP error status:', e.response.status)
      if (e.response.errors) throw new Error(e.response.errors.map(e => e.message).join(', '))
      if (e.response.error) throw new Error(e.response.error)
    } else {
      console.log("No response")
    }
    // Sentry.captureException(e);
  }
  //   if (!response) {
  //   throw new Error('Network response was not ok');
  // }
}


export const getPosts = async ({skip=0, limit = 100, width=500}) => {
  const query = gql`
  query getPosts($limit: Int, $skip: Int, $width: Int) {postsConnection (orderBy: createdAt_DESC, skip: $skip, first:$limit){
    aggregate {
      count
    }
      edges {
        node {
          ...PostFields
        }
      }
    }
  }
  `;
  const result = await request( query, { limit, skip, width });
  return {posts: result.postsConnection.edges, aggregate: result.postsConnection.aggregate};
};

export const getPostsForPath = async () => {
  const query = gql`
  query PostsPath {postsConnection (orderBy: createdAt_DESC, first:1000){
      edges {
        node {
          slug
          category# {
          #   title
          #   slug
          # }
        }
      }
    }
  }
  `;
  const result = await request( query);
  return result.postsConnection.edges;
};

export const getPostDetails = async (slug, stage = "PUBLISHED", width=500) => {

  const query = gql`
    query GetPostDetails($slug : String!, $stage: Stage!, $width: Int 
      ) {
      post(where: {slug: $slug}, stage: $stage) {
        ...PostWithRTFFields
      }
    }
  `;
  try {
    const result = await request( query, { slug, stage, width });
    if(!result.post){
      throw new Error("Post with this slug does not exist " + slug)
    }
    return result.post;
  }
  catch(error) {
    console.log("Error getting post details", error)
    Sentry.captureException(error);
  }
};


export const getPageDetails = async (slug, stage="PUBLISHED", headerWidth=500) => {
  const query = gql`
  query GetPageDetails($slug: String!, $headerWidth: Int!, $stage: Stage!) {
    page(where: {url: $slug}, stage: $stage) {
      ...PageFields
    }
  }`;
  try {
    const result = await request( query, { slug, stage, headerWidth });
    if(!result.page){
      throw new Error(`${slug} does not exist`)
    }
    return result.page;
  }
  catch(error) {
    console.log("Error getting page:", error)
    Sentry.captureException(error);
  }
};


export const getPromotionBySlug = async (slug, stage="PUBLISHED") => {

  const query = gql`
    query getPromotionBySlug($slug : String!, $stage: Stage! 
      ) {
        promotion(where: {slug: $slug}, stage: $stage) {
          ...PromotionFields
      }
    }
  `;
  const result = await request( query, { slug, stage });
  if(!result.promotion){
    throw new Error("promotion with this slug does not exist " + slug)
  }
  return result.promotion;
};

export const getPostsByCategory = async (category, limit, width=500) => {
  const query = gql`
    query GetPostDetails($category: Categories, $limit: Int, $width: Int) {
      posts(where: {category: $category}, first: $limit) {
        ...PostFields
      }
    }
  `;
  const result = await request( query, { category, limit, width });
  return result;
};

export const getPostsBySubcategory = async (subCategory, limit, width) => {
  const query = gql`
    query GetPostsByCategory($subCategory : Tags, $limit: Int, $width: Int) {
      posts(where: {subCategory: $subCategory}, first: $limit) {
        ...PostFields
      }
    }
  `;
  const result = await request( query, { subCategory, limit, width:500 });
  return result.posts;
};

export const getMoreFromTheSameCategory = async (categorySlug, limit, slugExtracted) => {
  const query = gql`
  query getMoreFromTheSameCategory($limit: Int!, 
  # $slugExtracted: String!, 
  $categorySlug: Categories) {
    posts(
      where: {category: $categorySlug
      # ,  AND : {slug_not: $slugExtracted }
      }, first: $limit) {
      ...PostFields
    }
  }
  `;
  const result = await request( query, { categorySlug, limit, slugExtracted });
  return result.posts;
};


export const getAuthors = async (width=500) => {
  const query = gql`
  query getAuthors($width: Int!) {
    authorsConnection {
      edges {
        node {
          ...AuthorFields
          biography
        }
      }
    }
  }
  `;
  const result = await request( query, {width});
  return result.authorsConnection.edges;
};

export const getAuthorDetails = async (slug) => {
  const query = gql`
  query GetAuthorDetails($slug : String!, $width: Int) {
    author(where: {slug: $slug}) {
      ...AuthorFields
      biography
    }
  }
`;
  const result = await request( query, { slug });
  return result.author;
}

export const getDeeplinkByName = async (name, endpoint) => {
  const query = gql`
    query getDeeplinkByName($name : String!) {
      deepLink(where: {name: $name}) {
        affiliate_link
      }
    }
  `;
  const result = await _request( endpoint, query, { name });
  return result.deepLink;
};


export function getDeeplinkDetails(params) {
  throw new Error('Not Implemented')
}

export function getDeeplinks(params) {
  throw new Error('Not Implemented')
}

export const GET_POSTS_BY_AUTHOR = gql`
query GetAuthorPosts($slug : String, $limit: Int, $offset: Int) {
  postsConnection(orderBy: createdAt_DESC, first: $limit, skip: $offset, where: {author: {slug: $slug} }) {
    edges {
      node {
        ...PostFields
      }
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
      pageSize
    }
  }
}
`;



export const GET_POSTS_BY_CATEGORY = gql`
query getPostsByCategory( $category : String = "", $limit: Int, $offset: Int,  $width: Int) {
  postsConnection(orderBy: createdAt_DESC, first: $limit, skip: $offset, where: {category: $category}) {
    aggregate {
      count
    }
    edges {
      node {
       ...PostFields
      }
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
      pageSize
    }
  }
}
`;

export const getAllCompliances = async () => {
  const query = gql`
  query getAllCompliances {
    compliancepages {
      id
      slug
    }
  }  
  `;
  const result = await request( query);
  return result.compliancepages;
};

export const getComplianceDetails = async (slug) => {

  const query = gql`
  query GetComplianceDetails($slug : String!) {
    compliancePage (where: {slug: $slug}) {
          id
          slug
          title
          content {
            raw
          }
        }
      }
      `;

  const result = await request( query, { slug });
  return result.compliancePage;
}


export const grpahCMSImageLoader = ({ src, width }) => {
  const relativeSrc = (src) => src.split('/').pop();

  return `https://media.graphassets.com/output=format:webp/resize=width:${width}/${relativeSrc(
    src
  )}`;
}


export const cdnLoader = ({ src, width, quality }) => {
  return `https://cdn.stdwatch.com${src.replace('images', 'optimized')}?w=${width}&q=${quality || 75}`
}




//New 
export const getCategoryPageBySlug = async (categorySlug) => {
  categorySlug = slugify(categorySlug)

  const query = gql`
  query CategoryPage($categorySlug: String = "") {
    categoryPage(where: {slug: $categorySlug}) {
      category {
        # title
        id
      }
      title
      subCategories {
        slug
        url
        title
      }
      disclaimer{
        text
      }
      seo{
        slug
        title
        description
        image {
          id
          url
        }
      }
    }
  }
  `;
  let result;
  try {

    result = await request( query, { categorySlug });
    // console.info(result.categoryPage.subCategories)
  }
  catch (e) {
    // console.log(e)
    if (categorySlug === 'sexual-health') {
      return {
        categoryPage: {
          category: { title: 'Sexual Health', id: 'Sexual_Health' },
          title: 'Sexual Health',
          subtitle: 'Your Guide to STDs: Prevention, Care, and Treatment',
          subCategories: [
            { slug: 'std-symptoms', url: null, title: 'STD Symptoms' },
            { slug: 'std-tests', url: null, title: 'STD Tests' },
            { slug: 'std-treatment', url: null, title: 'STD Treatment' },
            { slug: 'chlamydia', url: null, title: 'Chlamydia' },
            { slug: 'hiv', url: null, title: 'HIV' },
            { slug: 'trichomoniasis', url: null, title: 'Trichomoniasis' },
            { slug: 'gonorrhea', url: null, title: 'Gonorrhea' },
            { slug: 'syphilis', url: null, title: 'Syphilis' },
            { slug: 'hpv', url: null, title: 'HPV' },
            { slug: 'herpes', url: null, title: 'Herpes' },
            { slug: 'gardnerella', url: null, title: 'Gardnerella' },
            { slug: 'mycoplasma', url: null, title: 'Mycoplasma' },
            { slug: 'hepatitis', url: null, title: 'Hepatitis' },
            { slug: 'ureaplasma', url: null, title: 'Ureaplasma' }
          ]
        }
      }
    }
    else if (categorySlug.toLowerCase() === 'women-health') {
      return {
        categoryPage:
        {
          category: { title: "Women Health", id: "Women_Health" },
          title: "Women Health",
          subtitle: "Nurturing Fertility, Embracing Hormonal Health",
          subCategories: [
            // {
            //   slug: 'general-women-health',
            //   url: null,
            //   title: 'General Women Health'
            // },
            // { slug: 'female-fertility', url: null, title: 'Female Fertility' },
            // { slug: 'progesterone', url: null, title: 'Progesterone' },
            // { slug: 'female-hormone', url: null, title: 'Female Hormone' },
            // { slug: 'vaginitis', url: null, title: 'Vaginitis' }
          ]
        }
      }
    }
    else if (categorySlug === 'men-health') {
      return {
        categoryPage: {
          category: { title: 'Men Health', id: 'Men_Health' },
          title: 'Men Health',
          subtitle: "The Modern Man's Health Guide",
          subCategories: [
            // {
            //   slug: 'general-men-health',
            //   url: null,
            //   title: 'General Men Health'
            // },
            { slug: 'male-hormone', url: null, title: 'Male Hormone' },
            { slug: 'testosterone', url: null, title: 'Testosterone' }
          ]
        }
      }
    }
    else if (categorySlug === 'health-and-wellness') {
      return {
        categoryPage: {
          category: { title: 'Health and Wellness', id: 'Health_and_Wellness' },
          title: 'Health and Wellness',
          subtitle: "Health Essentials: Navigating Vitamins, Diabetes, and Heart Wellness",
          subCategories: [
            // { slug: 'general-wellness', url: null, title: 'General Wellness' },
            { slug: 'thyroid', url: null, title: 'Thyroid' },
            { slug: 'colon-cancer', url: null, title: 'Colon Cancer' },
            { slug: 'vitamins', url: null, title: 'Vitamins' },
            // { slug: 'celiac', url: null, title: 'Celiac' },
            // { slug: 'kidney', url: null, title: 'Kidney' },
            { slug: 'diabetes', url: null, title: 'Diabetes' },
            // { slug: 'lyme-disease', url: null, title: 'Lyme Disease' },
            // { slug: 'micronutrient', url: null, title: 'Micronutrient' },
            { slug: 'cholesterol', url: null, title: 'Cholesterol' },
            // { slug: 'crp', url: null, title: 'CRP' },
            // { slug: 'folate', url: null, title: 'Folate' }
          ]
        }
      }
    }
  }
  return result
}

export const getPostsByCategoryId = async (categoryId, skip=0, limit=10, width=500) => {

  const query = gql`
  # query PostsByCategoryId($categoryId: ID = "", $limit: Int) {
  query PostsByCategoryId($categoryId: Categories, $skip: Int, $limit: Int, $width: Int){
    postsConnection(where: {category: $categoryId}, first: $limit, skip: $skip, orderBy: createdAt_DESC) {
      aggregate {
        count
      }
      edges {
        node {
          ...PostFields
        }
      }
    }
  }
`;
  const result = await request( query, { categoryId, limit, skip, width });
  return result.postsConnection;
}
function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
export const getPostsByCategorySubcategorySlug = async ({categorySlug, subcategorySlug, skip, limit, width=500}) => {
  categorySlug = categorySlug.split('-').map(w => capitalizeFirstLetter(w)).map(w => w.length === 3 ? w.toUpperCase() : w).join('_')
  subcategorySlug = subcategorySlug.split('-').map(w => capitalizeFirstLetter(w)).map(w => w.length === 3 ? w.toUpperCase() : w).join('_')

  // subcategorySlug = subcategorySlug.split('-').map(w => capitalizeFirstLetter(w)).join('_')
  const query = gql`
  query PostsByCategorySubcategorySlug(
    # $categorySlug: Categories,
    $subcategorySlug: Tags,
    $skip: Int,
    $limit: Int,
    $width: Int
    ) {
    # posts(where: {category: {slug: $categorySlug}, subcategory: {slug: $subcategorySlug}}, first: $limit, orderBy: createdAt_DESC) {
    postsConnection(where: { subCategory: $subcategorySlug}, skip: $skip, first: $limit, orderBy: createdAt_DESC) {
      aggregate {
        count
      }
      edges {
        node {
          ...PostFields
        }
      }
    }
  }  
`;

  const result = await request( query, { subcategorySlug, skip, limit, width });
  return {posts: result.postsConnection.edges, aggregate: result.postsConnection.aggregate};
}




export const getMenuByName = async (name) => {
  const query = gql`
  query Menu($name : String!) {
    values: navigationMenu(where: {name: $name}) {
      name
      content(first: 50) {
        ... on NavMenuLink {
          id
          NavMenuLink_title_alias: title
          NavMenuLink_url_alias: url
          NavMenuLink_isColored_alias: isColored
        }
        ... on NavMenuDropdown {
          id
          NavMenuDropdown_title_alias: title
          NavMenuDropdown_links_alias: links(first: 50) {
            id
            title
            url
            isColored
          }
        }
      }
    }
  }
    `;

  const result = await request( query, { name });
  return result.values;
};